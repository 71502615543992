const { REACT_APP_CAPCHA } = process.env;

import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

// landing pages
const Landingpage = Loadable(lazy(() => import('../views/pages/landingpage/Landingpage')));
const Invited = Loadable(lazy(() => import('../views/invited/Invited')));
const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/auth1/Register')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const ErrorCabinet = Loadable(lazy(() => import('../views/authentication/ErrorCabinet')));

// office pages
import {
  PrivateRoute,
  ProfileOfficePage,
} from "../views/pages/office/index";
import { IndexLanding } from 'src/views/pages/landingpage/index/IndexLanding';
import { HomePageOffice } from 'src/views/pages/office/home/HomePageOffice';
import { AlertOfficePage } from 'src/views/pages/office/alert/AlertOfficePage';
import { SupportOfficePage } from 'src/views/pages/office/support/SupportOfficePage';
import { PartnersOfficePage } from 'src/views/pages/office/partners/PartnersOfficePage';
import { TopUpBalance } from 'src/views/pages/office/balance/top-up-balance/TopUpBalance';
import { TransferMoneyOfficePage } from 'src/views/pages/office/balance/transfer/TransferMoneyOfficePage';
import { WithdrawFromBalance } from 'src/views/pages/office/balance/withdraw-from-balance/WithdrawFromBalance';
import { BalanceHistory } from 'src/views/pages/office/balance/history/BalanceHistory';
import { ProgramsOfficePage } from 'src/views/pages/office/programs/ProgramsOfficePage';
import { PromoOfficePage } from 'src/views/pages/office/promo/PromoOfficePage';
import { AnalizPageOffice } from 'src/views/pages/office/analiz/AnalizPageOffice';
import { PoliticsIndex } from 'src/views/pages/landingpage/politics/PoliticsIndex';
import { ReviewsOfficeIndex } from 'src/views/pages/office/reviews-office/ReviewsOfficeIndex';
// import { PartnersFold } from 'src/views/pages/office/partners-fold/PartnersFold';

// import { CheckAuth } from './CheckAuth';

const Router = [
  // layout офиса
  {
    path: '/office',
    element: <PrivateRoute component={<FullLayout />} />,
    children: [
      { index: true, element: <Navigate to='home' /> },
      { path: 'home', element: <HomePageOffice /> },
      { path: 'profile', element: <ProfileOfficePage /> },
      { path: 'alert', element: <AlertOfficePage /> },
      // { path: 'partners-fold', element: <PartnersFold /> },
      { path: 'partners', element: <PartnersOfficePage /> },
      { path: 'top-up-balance', element: <TopUpBalance /> },
      { path: 'withdraw-from-balance', element: <WithdrawFromBalance /> },
      { path: 'transfer-money', element: <TransferMoneyOfficePage /> },
      { path: 'finance-history', element: <BalanceHistory /> },
      { path: 'promo', element: <PromoOfficePage /> },
      { path: 'support', element: <SupportOfficePage /> },
      { path: 'reviews', element: <ReviewsOfficeIndex /> },
      // маркетосы
      { path: 'programs', element: <ProgramsOfficePage /> },
      { path: 'space', element: <ProgramsOfficePage /> },
      { path: 'take', element: <ProgramsOfficePage /> },
      { path: 'null-program', element: <ProgramsOfficePage /> },
      { path: 'mini-program', element: <ProgramsOfficePage /> },
      { path: 'midi-program', element: <ProgramsOfficePage /> },
      { path: 'maxi-program', element: <ProgramsOfficePage /> },
      { path: 'auto-program', element: <ProgramsOfficePage /> },
      { path: 'home-program', element: <ProgramsOfficePage /> },
      { path: 'shot-program', element: <ProgramsOfficePage /> },
      { path: 'cat-one', element: <ProgramsOfficePage /> },
      { path: 'cat-two', element: <ProgramsOfficePage /> },
      { path: 'cat-three', element: <ProgramsOfficePage /> },
      { path: 'cat-four', element: <ProgramsOfficePage /> },
      { path: 'cat-five', element: <ProgramsOfficePage /> },
      { path: 'kitty-one', element: <ProgramsOfficePage /> },
      { path: 'kitty-two', element: <ProgramsOfficePage /> },
      { path: 'kitty-three', element: <ProgramsOfficePage /> },
      { path: 'kitty-four', element: <ProgramsOfficePage /> },

      // анализ
      { path: 'structure', element: <AnalizPageOffice /> },
      { path: '*', element: <ErrorCabinet /> },
    ],
  },
  // layout лендинга
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { index: true, element: <IndexLanding /> },
      { path: 'politics', element: <PoliticsIndex /> },
      { path: 'invited/:login', element: <Invited /> },
      { path: '*', element: <Navigate to="404" /> },
      { path: '404', element: <Error /> },
    ],
  },
  { path: 'login', element: <Login /> },
  { path: 'signup', element: <Register /> },
];

export default Router;
